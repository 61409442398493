import * as React from "react"
import { ButtonGatsby, ButtonTheme, Text, Box } from "../elements"
import { Colors } from "../materials"
import { Footer, SectionMessageWithGif, SEO, Header } from "../components"

const NotFoundPage: React.FC = () => (
  <>
    <SEO />
    <Header />
    <main>
      <Box py={12}>
        <SectionMessageWithGif
          title="Oups!! This is awkward."
          text={() => (
            <Box maxWidth="50ch">
              <Text>The page you are trying to access doesn't exist.</Text>
              <Box mt={6} />
              <ButtonGatsby theme={ButtonTheme.MAIN} to="/">
                Go to homepage
              </ButtonGatsby>
            </Box>
          )}
          icon="SvgVideoGamePacmanEnemy"
          iconStyle={{
            path: {
              strokeWidth: 1.5,
              stroke: Colors.oc.gray[9],
            },
            ellipse: {
              stroke: Colors.oc.gray[9],
              fill: Colors.oc.white,
            },
            "path:first-of-type": {
              stroke: Colors.oc.gray[9],
              fill: Colors.tw.red["600"],
            },
          }}
          gif={{
            href: "https://giphy.com/gifs/power-rangers-morphin-OOZLyBA9Euq2I",
            src: "/videos/power-ranger-fail.2.gif",
            alt: "Power Rangers Running GIF",
          }}
          dividerProps={{
            bg: Colors.oc.gray[6],
            css: {
              filter: `drop-shadow( 0px 2px 2px ${Colors.oc.gray[5]})`,
            },
          }}
        />
      </Box>
    </main>
    <Footer />
  </>
)

export default NotFoundPage
